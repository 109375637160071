import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { Dispatch } from 'redux'
import {debounce} from 'debounce'
import {createGqlClient} from '../../graphql-client.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { ApplicationState } from '../../redux/store.js'
import { User } from '../../types/user.js'
import './UserEditForm.css'
import { getSingleUser } from '../../redux/user.js'

type UserEditFormProps = {
    dispatch: Dispatch,
    user: User,
    editingUser: User
} & PathRouteProps

class UserEditForm extends React.Component<UserEditFormProps,{}> {

    searchString = ''

    state = {
        name: this.props.editingUser.name,
        role: this.props.editingUser.role,
        message: '',
        equipmentSearchResults: [],
        accessGroupSearchResults: []
    }

    async submit(e : Event) {
        e.preventDefault()

        this.setState({
            message: ''
        })
        
        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                userId: this.props.editingUser.id,
                user: {
                    name: this.state.name,
                    role: this.state.role
                }
            },
            mutation: gql`
            mutation ($userId: ID!, $user:UpdateUserInputType!) {
                updateUser(id: $userId, user: $user) {
                    code
                    success
                    message
                }
              }
            `
        })

        if (result.data.updateUser.success === false) {
            this.setState({message: result.data.updateUser.message})
            return;
        }

        window.location.href = '/settings/users'
    }

    debouncedSearchAccessGroup = debounce(this.searchAccessGroup, 20)

    debouncedSearchEquipment = debounce(this.searchEquipment, 20)

    async searchAccessGroup(searchString: string) {
        this.searchString = searchString
        if (searchString == ''){
            this.setState({
                accessGroupSearchResults: []
            })
            return;
        }
        
        const apolloClient = createGqlClient()
        const results = await apolloClient.query({
            query: gql`
            query Search {
                search(searchString: "${searchString}", count: 8, type: ACCESS_GROUP){
                    id
                    name
                    type
                }
            }
            `
        })

        if (this.searchString != searchString) {
            return
        }
        
        this.setState({
            accessGroupSearchResults: results.data.search
        })
    }

    async searchEquipment(searchString: string) {
        this.searchString = searchString
        if (searchString == ''){
            this.setState({
                equipmentSearchResults: []
            })
            return;
        }
        
        const apolloClient = createGqlClient()
        const results = await apolloClient.query({
            query: gql`
            query Search {
                search(searchString: "${searchString}", count: 8, type: EQUIPMENT){
                    id
                    name
                    type
                }
            }
            `
        })

        if (this.searchString != searchString) {
            return
        }
        
        this.setState({
            equipmentSearchResults: results.data.search
        })
    }

    async addAccessGroup(id) {
        this.setState({
            accessGroupSearchResults: []
        })

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                userId: this.props.editingUser.id,
                accessGroupId: id
            },
            mutation: gql`
            mutation ($userId:ID!, $accessGroupId:ID!) {
                addAccessGroupToUser(userId: $userId, accessGroupId: $accessGroupId) {
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleUser(apolloClient, this.props.editingUser.id)(this.props.dispatch)
    }

    async removeAccessGroup(e, id) {
        e.preventDefault()

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                userId: this.props.editingUser.id,
                accessGroupId: id
            },
            mutation: gql`
            mutation ($userId:ID!, $accessGroupId:ID!) {
                removeAccessGroupFromUser(userId: $userId, accessGroupId: $accessGroupId) {                  
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleUser(apolloClient, this.props.editingUser.id)(this.props.dispatch)
    }

    async addEquipment(id) {
        this.setState({
            equipmentSearchResults: []
        })

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                userId: this.props.editingUser.id,
                equipmentId: id
            },
            mutation: gql`
            mutation ($userId:ID!, $equipmentId:ID!) {
                addEquipmentToUser(userId: $userId, equipmentId: $equipmentId) {
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleUser(apolloClient, this.props.editingUser.id)(this.props.dispatch)
    }

    async removeEquipment(e, id) {
        e.preventDefault()

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                userId: this.props.editingUser.id,
                equipmentId: id
            },
            mutation: gql`
            mutation ($userId:ID!, $equipmentId:ID!) {
                removeEquipmentFromUser(userId: $userId, equipmentId: $equipmentId) {                  
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleUser(apolloClient, this.props.editingUser.id)(this.props.dispatch)
    }

    render() {
        return (
            <Scaffold {...this.props} autofocusSearch={false}>
                <header className="scaffold-header">Edit User</header>
                <header className="header-error">{this.state.message}</header>
                <form onSubmit={this.submit.bind(this)}>
                    <div>
                        <div className="input-field-container">
                            <label className="input-field-label">Name<input autoFocus type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                            </label>
                        </div>
                        {this.props.user.id !== this.props.editingUser.id &&
                        <div className="input-field-container scaffold-select-container">
                            <label className="input-field-label">Role
                                <div className="useredit-select-container">
                                    <label className={'scaffold-select useredit-select'}>
                                        <select value={this.state.role} onChange={e => this.setState({role: e.target.value})}>
                                            <option value="ADMIN">Admin</option>
                                            <option value="TEACHER">Teacher</option>
                                        </select>
                                    </label>
                                </div>
                            </label>
                        </div>}
                        <div className="input-field-container">
                            <label className="input-field-label">Manage Access Group</label>
                            <div className="ag-list-item input-search-list-container">
                            <input type="search" placeholder="Access Group name" 
                                onChange={e => this.debouncedSearchAccessGroup(e.target.value)}></input>
                            {this.state.accessGroupSearchResults.length > 0 &&
                                <ul className="input-search-list">
                                    {this.state.accessGroupSearchResults.map(sr => (
                                        <li key={sr.id} onClick={e => this.addAccessGroup(sr.id)} className="input-search-listitem">{sr.name}</li>
                                    ))}
                                </ul>
                            }
                            </div>
                            <div className="scaffold-card-body-list">
                                {this.props.editingUser.manageAccessGroups.map(l => (
                                    <div key={l.id} className="ag-list-item"><a href={`/accessgroup/id/${l.id}`}>{l.name}</a> <button onClick={e => this.removeAccessGroup(e, l.id)} className="ag-btn-small">x</button></div>
                                ))}
                            </div>
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-label">Manage Equipment</label>
                            <div className="ag-list-item input-search-list-container">
                            <input type="search" placeholder="Equipment name" 
                                onChange={e => this.debouncedSearchEquipment(e.target.value)}></input>
                            {this.state.equipmentSearchResults.length > 0 &&
                                <ul className="input-search-list">
                                    {this.state.equipmentSearchResults.map(sr => (
                                        <li key={sr.id} onClick={e => this.addEquipment(sr.id)} className="input-search-listitem">{sr.name}</li>
                                    ))}
                                </ul>
                            }
                            </div>
                            <div className="scaffold-card-body-list">
                                {this.props.editingUser.manageEquipment.map(l => (
                                    <div key={l.id} className="ag-list-item"><a href={`/equipment/id/${l.id}`}>{l.name}</a> <button onClick={e => this.removeEquipment(e, l.id)} className="ag-btn-small">x</button></div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="primary">Edit</button>
                </form>
            </Scaffold>
        )
    }
}

const mapStateToProps = ({userState, userListState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        editingUser: userListState.users[0]
    })
}

export default connect(mapStateToProps)(UserEditForm)