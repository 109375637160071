const sendApiRequest = async (method: string, path: string, body?: object) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: body ? JSON.stringify(body) : null
    }

    const response = await fetch(path, options)

    let responseJson = { message: "" }

    try {
        responseJson = await response.json()
    }
    catch{}

    return {
        success: response.status === 200,
        statusCode: response.status,
        body: responseJson
    }
}

export const sendPostRequest = async (path: string, body?: object) => sendApiRequest('POST', path, body)

export const sendPutRequest = async (path: string, body?: object) => sendApiRequest('PUT', path, body)