import React, { Dispatch } from 'react'
import './Account.css'
import LoginScaffold from './LoginScaffold.js'
import queryString from 'query-string'
import { resetPassword } from './resetPasswordActions.js'

type AcceptInviteFormProps =
    {
        dispatch: Dispatch<any>,
        history: History,
        location: Location
    }

class AcceptInviteForm extends React.Component<AcceptInviteFormProps, {}> {

    state = {
        password: "",
        error: "",
        displaySuccess: false,
        showPassword: false
    }

    async submit(e : Event) {
        e.preventDefault()
        const { token } = queryString.parse(this.props.location.search)

        const response = await resetPassword(token as string, this.state.password)

        if (!response.success) {
            this.setState({error: response.message})
        }
        else {
            this.setState({displaySuccess: true})
        }
    }

    render() {
        return (
            <LoginScaffold>
            {this.state.displaySuccess
              ? <div>
                    <header className="Login-header">Success. Tap the link below to continue.</header>
                    <a className="btn primary" href="/">Continue</a>
                </div>
              : <div>
                    <header className="Login-header">In order to accept the invite, please choose a password below.</header>
                    <header className="Login-header-error">{this.state.error}</header>
                    <form className="Login-form" onSubmit={this.submit.bind(this)}>
                        <div>
                            <input autoFocus={true} type={this.state.showPassword ? "text" : "password"} className="Login-input" placeholder="Password" value={this.state.password} onChange={e => this.setState({password: e.target.value})}></input>
                            <label><input type="checkbox" className="Login-input-checkbox" checked={this.state.showPassword} onChange={e => this.setState({showPassword: e.target.checked})}></input><span>Show</span></label>
                        </div>
                        <button type="submit" className="primary">Accept Invite</button>
                    </form>
                </div>
            }
            </LoginScaffold>
        )
    }
}

export default AcceptInviteForm;