import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import {createGqlClient} from '../../graphql-client.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { User } from '../../types/user.js'
import { ApplicationState } from '../../redux/store.js'

type EquipmentAddFormProps = {
    dispatch: Dispatch
    user: User
} & PathRouteProps

class EquipmentAddForm extends React.Component<EquipmentAddFormProps,{}> {

    state = {
        name: '',
        description: '',
        location: '',
        interlockId: '',
        message: ''
    }

    async submit(e : Event) {
        e.preventDefault()
        
        this.setState({
            message: ''
        })
        
        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                equipment: {
                    name: this.state.name,
                    description: this.state.description,
                    location: this.state.location,
                    interlockId: this.state.interlockId
                }
            },
            mutation: gql`
            mutation ($equipment:CreateEquipmentInputType!) {
                createEquipment(equipment: $equipment) {
                  success
                  code
                  message
                }
              }
            `
        })

        if (result.data.createEquipment.success === false) {
            this.setState({message: result.data.createEquipment.message})
            return;
        }

        window.location.href = '/equipment'
    }

    render() {
        return (
            <Scaffold {...this.props} autofocusSearch={false}>
                <header className="scaffold-header">Add Equipment</header>
                <header className="header-error">{this.state.message}</header>
                <form onSubmit={this.submit.bind(this)}>
                    <div>
                        <div><input autoFocus placeholder="Name" type="text" onChange={e => this.setState({name: e.target.value})}/></div>
                        <div><input placeholder="Description" type="text" onChange={e => this.setState({description: e.target.value})}/></div>
                        <div><input placeholder="Location" type="text" onChange={e => this.setState({location: e.target.value})}/></div>
                        <div><input placeholder="Interlock ID" type="text" onChange={e => this.setState({interlockId: e.target.value})}/></div>
                    </div>
                    <button type="submit" className="primary">Add</button>
                </form>
            </Scaffold>
        )
    }
}

const mapStateToProps = ({userState} : ApplicationState) => {
    return (
    {
        user: userState.user
    })
}

export default connect(mapStateToProps)(EquipmentAddForm)
