import {getSingleEquipment, getEquipmentCollection, getInterlockSettings} from '../redux/equipment.js'
import {getOperatorCollection, getSingleOperator} from '../redux/operator.js'
import EquipmentList from './Equipment/EquipmentList.js'
import EquipmentDetail from './Equipment/EquipmentDetail.js'
import OperatorList from './Operators/OperatorList.js'
import OperatorDetail from './Operators/OperatorDetail.js'
import LoginForm from './Account/LoginForm.js'
import SignupForm from './Account/SignupForm.js'
import ForgotPasswordForm from './Account/ForgotPasswordForm.js'
import ResetPasswordForm from './Account/ResetPasswordForm.js'
import OperatorAddForm from './Operators/OperatorAddForm.js'
import EquipmentAddForm from './Equipment/EquipmentAddForm.js'
import OperatorEditForm from './Operators/OperatorEditForm.js'
import EquipmentEditForm from './Equipment/EquipmentEditForm.js'
import AccessGroupList from './AccessGroups/AccessGroupList.js'
import AccessGroupDetail from './AccessGroups/AccessGroupDetail.js'
import AccessGroupAddForm from './AccessGroups/AccessGroupAddForm.js'
import AccessGroupEditForm from './AccessGroups/AccessGroupEditForm.js'
import { getSingleAccessGroup, getAccessGroupCollection } from '../redux/accessGroup.js'
import Settings from './Settings/Settings.js'
import UserList from './Settings/UserList.js'
import { getCurrentUser, getUserList, getSingleUser } from '../redux/user.js'
import UserEditForm from './Settings/UserEditForm.js'
import AcceptInviteForm from './Account/AcceptInviteForm.js'
import NotFound from './NotFound.js'
import InterlockSettingsForm from './Equipment/InterlockSettingsForm.js'
import { ApolloClient } from "@apollo/client/apollo-client.min.cjs"
import { PathMatch } from 'react-router-dom'
import { ApplicationState } from '../redux/store.js'
import { Store } from 'redux'

export interface RouteDefinition {
  requiresAuthorization: boolean,
  requiresUnauthenticated: boolean,
  requiresRole?: string,
  path: string,
  component: React.JSXElementConstructor<any>,
  fetchInitialData?: (
    route: RouteDefinition & { match: PathMatch<string> }, 
    store: Store<ApplicationState, any>, 
    apolloClient: ApolloClient) => Promise<void>
}

export const routeDefinitions : RouteDefinition[] =  [
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/',
    component: EquipmentList,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getEquipmentCollection(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/equipment',
    component: EquipmentList,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getEquipmentCollection(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/equipment/add',
    component: EquipmentAddForm,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getCurrentUser(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/equipment/id/:id',
    component: EquipmentDetail,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getSingleEquipment(apolloClient, id))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/equipment/edit/:id',
    component: EquipmentEditForm,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getSingleEquipment(apolloClient, id))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/equipment/interlocksettings/:id',
    component: InterlockSettingsForm,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getInterlockSettings(apolloClient, id))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/operators',
    component: OperatorList,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getOperatorCollection(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/operators/id/:id',
    component: OperatorDetail,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getSingleOperator(apolloClient, id))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/operators/edit/:id',
    component: OperatorEditForm,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getSingleOperator(apolloClient, id))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/operators/add',
    component: OperatorAddForm,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getCurrentUser(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/accessgroups',
    component: AccessGroupList,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getAccessGroupCollection(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/accessgroups/id/:id',
    component: AccessGroupDetail,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getSingleAccessGroup(apolloClient, id))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/accessgroups/edit/:id',
    component: AccessGroupEditForm,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getSingleAccessGroup(apolloClient, id))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/accessgroups/add',
    component: AccessGroupAddForm,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getCurrentUser(apolloClient))
    }
  },
  {
    requiresAuthorization: false,
    requiresUnauthenticated: true,
    path: '/login',
    component: LoginForm
  },
  {
    requiresAuthorization: false,
    requiresUnauthenticated: true,
    path: '/signup',
    component: SignupForm
  },
  {
    requiresAuthorization: false,
    requiresUnauthenticated: true,
    path: '/forgot-password',
    component: ForgotPasswordForm
  },
  {
    requiresAuthorization: false,
    requiresUnauthenticated: true,
    path: '/reset-password',
    component: ResetPasswordForm
  },
  {
    requiresAuthorization: false,
    requiresUnauthenticated: true,
    path: '/accept-invite',
    component: AcceptInviteForm
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/settings',
    requiresRole: 'ADMIN',
    component: Settings,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getCurrentUser(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    path: '/settings/users',
    requiresRole: 'ADMIN',
    component: UserList,
    fetchInitialData: async (route, store, apolloClient) => {
      await store.dispatch(getUserList(apolloClient))
    }
  },
  {
    requiresAuthorization: true,
    requiresUnauthenticated: false,
    requiresRole: 'ADMIN',
    path: '/settings/users/edit/:id',
    component: UserEditForm,
    fetchInitialData: async (route, store, apolloClient) => {
      const id = route.match.params.id as string

      await store.dispatch(getSingleUser(apolloClient, id))
    }
  },
  {
    requiresAuthorization: false,
    requiresUnauthenticated: false,
    path: '*',
    component: NotFound
  }
]

export default routeDefinitions