import React, { useState } from 'react'
import './Account.css'
import LoginScaffold from './LoginScaffold.js'
import { resetPassword } from './resetPasswordActions.js'
import { useSearchParams } from 'react-router-dom'

function ResetPasswordForm() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [resetPasswordState, setResetPasswordState] = useState({
        password: "",
        error: "",
        displaySuccess: false,
        showPassword: false
    });

    async function submit(e : Event) {
        e.preventDefault()

        const token = searchParams.get("token")

        const response = await resetPassword(token as string, resetPasswordState.password)

        if (!response.success) {
            setResetPasswordState({...resetPasswordState, error: response.message})
        }
        else {
            setResetPasswordState({...resetPasswordState, displaySuccess: true})
        }
    }

    return (
        <LoginScaffold>
        {resetPasswordState.displaySuccess
            ? <div>
                <header className="Login-header">Password succesfully changed.</header>
                <a className="btn primary" href="/">Continue</a>
            </div>
            : <div>
                <header className="Login-header">Enter your new password.</header>
                <header className="Login-header-error">{resetPasswordState.error}</header>
                <form className="Login-form" onSubmit={submit.bind(this)}>
                    <div>
                        <input autoFocus={true} type={resetPasswordState.showPassword ? "text" : "password"} className="Login-input" placeholder="Password" value={resetPasswordState.password} onChange={e => setResetPasswordState({...resetPasswordState, password: e.target.value})}></input>
                        <label><input type="checkbox" className="Login-input-checkbox" checked={resetPasswordState.showPassword} onChange={e => setResetPasswordState({...resetPasswordState, showPassword: e.target.checked})}></input><span>Show</span></label>
                    </div>
                    <button type="submit" className="primary">Change Password</button>
                </form>
            </div>
        }
        </LoginScaffold>
    )
}

export default ResetPasswordForm;