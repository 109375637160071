import React, { Dispatch } from 'react'
import './Account/Account.css'
import LoginScaffold from './Account/LoginScaffold.js'

type NotFoundProps =
    {
        dispatch: Dispatch<any>,
        history: History
    } & any

class NotFound extends React.Component<NotFoundProps, {}> {

    render() {
        return (
            <LoginScaffold>
                <header className="Login-header">Sorry! The page you requested cannot be found.</header>
                <a className="btn primary" href="/">Continue</a>
            </LoginScaffold>
        )
    }
}

export default NotFound