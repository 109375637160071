import React from 'react'
import './OperatorDetail.css'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { ApplicationState } from '../../redux/store.js'
import { Operator } from '../../types/operator.js'
import { Dispatch } from 'redux'
import { PathRouteProps } from 'react-router'
import { User } from '../../types/user.js'

type OperatorDetailProps = {
    dispatch: Dispatch
    user: User,
    operator: Operator
} & PathRouteProps

class OperatorDetail extends React.Component<OperatorDetailProps,{}> {
    render = () => (
        <Scaffold {...this.props}>
            <header className="scaffold-header">{this.props.operator.name}</header>
            <div className="scaffold-card-container">
                <div>
                    <div className="scaffold-card scaffold-card-bg-1">
                        <div className="scaffold-card-title">Details</div>
                        <div className="scaffold-card-subtitle"><strong>Disabled</strong> {this.props.operator.isDisabled ? 'yes' : 'no'}</div>
                        <div className="scaffold-card-subtitle"><strong>RFID</strong> {this.props.operator.rfid}</div>
                    </div>
                    <div className="scaffold-card scaffold-card-bg-1">
                        <div className="scaffold-card-title">Authorized equipment</div>
                        <div className="scaffold-card-body-list">
                            {this.props.operator.authorizedEquipment.map(l => (
                                <div key={l.id}><a href={`/equipment/id/${l.id}`}>{l.name}</a></div>
                            ))}
                        </div>
                    </div>
                    <div className="scaffold-card scaffold-card-bg-1">
                        <div className="scaffold-card-title">Access Groups</div>
                        <div className="scaffold-card-body-list">
                            {this.props.operator.accessGroups.map(l => (
                                <div key={l.id}><a href={`/accessgroups/id/${l.id}`}>{l.name}</a></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="scaffold-card scaffold-card-bg-3 scaffold-card-recent-activity">
                        <div className="scaffold-card-title">Recent activity</div>
                        <div className="scaffold-card-body-recent-activity">
                            {this.props.operator.recentActivity.map(re => (
                                <div key={re.id} className="row">
                                    <div className="col">{new Date(re.eventDateTime).toLocaleDateString()}</div>
                                    <div className="col">{new Date(re.eventDateTime).toLocaleTimeString()}</div>
                                    <div className="scaffold-card-recent-activity-status bg-color-2"><a href={`/equipment/id/${re.equipmentId}`}>{re.description}</a></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Scaffold>
    )
}

const mapStateToProps = ({userState, operatorState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        operator: operatorState.operators[0]
    })
}

export default connect(mapStateToProps)(OperatorDetail)