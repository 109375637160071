import React from 'react'
import {connect} from 'react-redux'
import { Dispatch } from 'redux'
import { PathRouteProps } from 'react-router'
import Scaffold from '../Scaffold/Scaffold.js'
import { ApplicationState } from '../../redux/store.js'
import { User } from '../../types/user.js'
import './UserList.css'
import { getUserList } from '../../redux/user.js'
import { createGqlClient } from '../../graphql-client.js'
import { gql } from '@apollo/client'

type UserListProps = {
    dispatch: Dispatch
    user: User,
    users: User[]
} & PathRouteProps

class UserList extends React.Component<UserListProps,{}> {

    state = {
        inviteName: '',
        inviteEmail: '',
        inviteRole: 'ADMIN',
        success: false,
        message: ''
    }

    async invite(e) {
        e.preventDefault()

        this.setState({message: ''})

        const apolloClient = createGqlClient()

        const result = await apolloClient.mutate({
            variables: {
                user: {
                    name: this.state.inviteName,
                    email: this.state.inviteEmail,
                    role: this.state.inviteRole
                }
            },
            mutation: gql`
            mutation ($user:InviteUserInputType!) {
                inviteUser(user: $user) {
                    code
                    success
                    message
                }
              }
            `
        })

        if (result.data.inviteUser.success === false) {
            this.setState({success:false, message: result.data.inviteUser.message})
            return;
        }

        this.setState({success:true, message: `Sent invitation to ${this.state.inviteName}`})

        await getUserList(apolloClient)(this.props.dispatch)
    }

    async deleteUser(e, userId: string) {
        const target = e.target

        if (!confirm('Are you sure you want to delete this user? This cannot be undone.')) {
            return
        }

        this.setState({message: ''})

        const apolloClient = createGqlClient()

        const result = await apolloClient.mutate({
            variables: {
                userId
            },
            mutation: gql`
            mutation ($userId: ID!) {
                deleteUser(id: $userId) {
                    code
                    success
                    message
                }
              }
            `
        })

        target.blur()
        if (result.data.deleteUser.success === false) {
            this.setState({success:false, message: result.data.deleteUser.message})
            return;
        }

        await getUserList(apolloClient)(this.props.dispatch)
    }

    titleCase = (value: string) => value[0] + value.substr(1).toLowerCase()
    
    render = () => (
        <Scaffold {...this.props}>
            <header className="scaffold-header">Users</header>
            <form onSubmit={e => this.invite(e)}>
                <input className="userlist-invite-input" value={this.state.inviteName} onChange={e => this.setState({inviteName: e.target.value})} placeholder="Name"></input>
                <input className="userlist-invite-input userlist-invite-input-email" value={this.state.inviteEmail} onChange={e => this.setState({inviteEmail: e.target.value})} placeholder="Email"></input>
                <div className="userlist-invite-select scaffold-select-container">
                    <label className="scaffold-select">
                        <select value={this.state.inviteRole} onChange={e => this.setState({inviteRole: e.target.value})}>
                            <option value="ADMIN">Admin</option>
                            <option value="TEACHER">Teacher</option>
                        </select>
                    </label>
                </div>
                <button className="userlist-invite-btn">Invite</button>
            </form>
            <header className={this.state.success ? "header-success" : "header-error"}>{this.state.message}</header>
            <div className="scaffold-card-container">
                {this.props.users.map(u => 
                    <div key={u.name} className="scaffold-card-menu-container">
                        <div className="scaffold-card-menu" tabIndex={0}>
                            <div className="scaffold-card-menu-item-container">
                                <a className="menu-item" href={`/settings/users/edit/${u.id}`}>Edit</a>
                                {this.props.user.id !== u.id && <button className="menu-item" onClick={e => this.deleteUser(e, u.id)}>Delete</button>}
                            </div>
                        </div>
                        <div className="scaffold-card scaffold-card-bg-1">
                            <div className="scaffold-card-title">
                                {u.name}
                            </div>
                            <div className="scaffold-card-detail">
                                <div className="scaffold-card-detail-label">Email</div> 
                                <a href={`mailto:${u.email}`}>{u.email}</a>
                            </div>
                            <div className="scaffold-card-detail">
                                <div className="scaffold-card-detail-label">Email Verified</div> 
                                {u.isConfirmed ? 'Verified' : 'Not Verified'}
                            </div>
                            <div className="scaffold-card-detail">
                                <div className="scaffold-card-detail-label">Role</div> 
                                {this.titleCase(u.role)}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Scaffold>
    )
}

const mapStateToProps = ({userState, userListState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        users: userListState.users
    })
}

export default connect(mapStateToProps)(UserList)