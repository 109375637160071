import { UserState, UserListState } from "../types/user.js"
import { Action, Reducer } from "redux"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { gql } from '@apollo/client'

export const CURRENT_USER_GET = 'CURRENT_USER_GET'
export const USER_COLLECTION_GET = 'USER_COLLECTION_GET'

export const defaultState: UserState = {
    user: {
        id: '',
        name: '',
        email: '',
        isConfirmed: false,
        role: 'ADMIN',
        manageAccessGroups: [],
        manageEquipment: []
    }
}

export const defaultUserListState: UserListState = {
    users: []
}

export interface UserAction {
    type: string
    data: UserState
}

export const getCurrentUser = (apolloClient: ApolloClient<NormalizedCacheObject>) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query getCurrentUser {
                user {
                    id
                    name
                    role
                }
            }
            `
        })

        return dispatch({
            type: CURRENT_USER_GET,
            data: results.data
        })
    } 
}

export const getUserList = (apolloClient: ApolloClient<NormalizedCacheObject>) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query getUserList {
                users {
                    id
                    name
                    email
                    isConfirmed
                    role
                }
            }
            `
        })

        return dispatch({
            type: USER_COLLECTION_GET,
            data: results.data
        })
    } 
}

export const getSingleUser = (apolloClient: ApolloClient<NormalizedCacheObject>, id: string) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query getUserList {
                users(id: "${id}") {
                    id
                    name
                    email
                    isConfirmed
                    role
                    manageEquipment {
                        id
                        name
                    }
                    manageAccessGroups {
                        id
                        name
                    }
                }
            }
            `
        })

        return dispatch({
            type: USER_COLLECTION_GET,
            data: results.data
        })
    } 
}

export const userState: Reducer<UserState> = (state = defaultState, incomingAction: Action) => {
    const action = incomingAction as UserAction

    switch (action.type) {
        case CURRENT_USER_GET:
            return action.data
        default:
            return state
    }
}

export interface UserListAction {
    type: string
    data: UserListState
}

export const userListState: Reducer<UserListState> = (state = defaultUserListState, incomingAction: Action) => {
    const action = incomingAction as UserListAction

    switch (action.type) {
        case USER_COLLECTION_GET:
            return action.data
        default:
            return state
    }
}
