import { gql } from '@apollo/client'
import { Action, Reducer } from "redux"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { EquipmentCollectionState } from "../types/equipment.js"

export const EQUIPMENT_COLLECTION_GET = 'EQUIPMENT_COLLECTION_GET'

export const getEquipmentCollection = (apolloClient: ApolloClient<NormalizedCacheObject>) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query getEquipmentCollection {
                equipment(count: 100) {
                    id
                    name
                    isOnline
                }
            }
            `
        })

        return dispatch({
            type: EQUIPMENT_COLLECTION_GET,
            data: results.data
        } as GetEquipmentAction)
    }
}

export const getSingleEquipment = (apolloClient: ApolloClient<NormalizedCacheObject>, equipmentId: string) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query GetSingleEquipment {
                equipment(id: "${equipmentId}"){
                    id
                    name
                    description
                    location
                    interlockId
                    isOnline
                    recentActivity(count: 100) {
                        id
                        eventDateTime
                        description
                        operatorId
                        activityKind
                    }
                }
            }
            `
        })
        
        return dispatch({
            type: EQUIPMENT_COLLECTION_GET,
            data: {
                equipment: results.data.equipment
            }
        } as GetEquipmentAction)
    } 
}

export const defaultState: EquipmentCollectionState = {
    equipment: [{
        id: '',
        name: '',
        description: '',
        location: '',
        interlockId: '',
        isOnline: false,
        metrics:[],
        mostUsedOperators:[],
        recentActivity:[],
        interlockSettings: {
            automaticOff: false,
            connectionFailed: true,
            disableRelayifRunning: false,
            isConfigured: false,
            isRelayInverted: false,
            isUnderMaintenance: false,
            localAccessDbUpdateIntervalMinutes: 0,
            minAmpsToDetermineRunningState: 0,
            turnEquipmentOffInSeconds: 0,
            updateLocalAccessDBAutomatically: false
        }
    }]
}

export interface GetEquipmentAction {
    type: string
    data: EquipmentCollectionState
}

export const equipmentState: Reducer<EquipmentCollectionState> = (state = defaultState, incomingAction: Action) => {
    const action = incomingAction as GetEquipmentAction

    switch (action.type) {
        case EQUIPMENT_COLLECTION_GET:
            return action.data
        default:
            return state
    }
}

export const getInterlockSettings = (apolloClient: ApolloClient<NormalizedCacheObject>, equipmentId: string) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query GetInterlockSetting {
                equipment(id: "${equipmentId}"){
                    id
                    name
                    isOnline
                    interlockSettings {
                        isRelayInverted
                        automaticOff
                        disableRelayifRunning
                        isRelayInverted
                        isUnderMaintenance
                        localAccessDbUpdateIntervalMinutes
                        minAmpsToDetermineRunningState
                        turnEquipmentOffInSeconds
                        updateLocalAccessDBAutomatically
                        connectionFailed
                        isConfigured
                    }
                }
            }
            `
        })

        return dispatch({
            type: EQUIPMENT_COLLECTION_GET,
            data: {
                equipment: results.data.equipment
            } as EquipmentCollectionState
        })
    } 
}