import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { ApplicationState } from '../../redux/store.js'
import { Operator } from '../../types/operator.js'
import debounce from 'debounce'
import { Dispatch } from 'redux'
import {createGqlClient} from '../../graphql-client.js'
import { getNextOperatorCollection } from '../../redux/operator.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { User } from '../../types/user.js'

type OperatorListProps = {
    dispatch: Dispatch
    user: User,
    operators: Operator[]
} & PathRouteProps

class OperatorList extends React.Component<OperatorListProps,{}> {
    componentDidMount() {
        window.onscroll = debounce(() => {
            const scrollPos = document.documentElement.scrollTop + window.innerHeight
            if (scrollPos >= document.documentElement.scrollHeight) {
                if (this.props.operators.length > 0) {
                    this.props.dispatch(getNextOperatorCollection(createGqlClient(), this.props.operators[this.props.operators.length - 1].id) as any)
                }
            }
          }, 100);
    }

    async deleteOperator(operatorId : string) {
        if (!window.confirm('Are you sure you want to delete?')) {
            return
        }

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                operatorId
            },
            mutation: gql`
            mutation ($operatorId: ID!) {
                deleteOperator(id: $operatorId) {
                    success
                    code
                    message
                }
              }
            `
        })
        window.location.href = window.location.href
    }

    render = () => (
        <Scaffold {...this.props}>
            <header className="scaffold-header">Operators</header>
            <div className="scaffold-card-container">
                {this.props.operators.map(o => 
                    <div key={o.id} className="scaffold-card-menu-container">
                        <div className="scaffold-card-menu" tabIndex={0}>
                            <div className="scaffold-card-menu-item-container">
                                <a className="menu-item" href={`/operators/edit/${o.id}`}>Edit</a>
                                <button className="menu-item" onClick={e => this.deleteOperator(o.id)}>Delete</button>
                            </div>
                        </div>
                        <a href={`/operators/id/${o.id}`} className="scaffold-card scaffold-card-bg-1 scaffold-card-link">
                            <div className="scaffold-card-title">
                                {o.name}
                            </div>
                        </a>
                    </div>
                )}
            </div>
            <a href="/operators/add" className="fab"></a>
        </Scaffold>
    )
}

const mapStateToProps = ({userState, operatorState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        operators: operatorState.operators
    })
}

export default connect(mapStateToProps)(OperatorList)