import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { ApplicationState } from '../../redux/store.js'
import { Dispatch } from 'redux'
import {createGqlClient} from '../../graphql-client.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { AccessGroup } from '../../types/accessGroup.js'
import { User } from '../../types/user.js'

type AccessGroupListProps = {
    dispatch: Dispatch
    user: User,
    accessGroups: AccessGroup[]
} & PathRouteProps

class AccessGroupList extends React.Component<AccessGroupListProps,{}> {
    async deleteAccessGroup(accessGroupId : string) {
        if (!window.confirm('Are you sure you want to delete?')) {
            return
        }

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                accessGroupId
            },
            mutation: gql`
            mutation ($accessGroupId: ID!) {
                deleteAccessGroup(id: $accessGroupId) {
                    success
                    code
                    message
                }
              }
            `
        })
        window.location.href = window.location.href
    }

    render = () => (
        <Scaffold {...this.props}>
            <header className="scaffold-header">Access Groups</header>
            <div className="scaffold-card-container">
                {this.props.accessGroups.map(o => 
                    <div key={o.id} className="scaffold-card-menu-container">
                        <div className="scaffold-card-menu" tabIndex={0}>
                            <div className="scaffold-card-menu-item-container">
                                <a className="menu-item" href={`/accessgroups/edit/${o.id}`}>Edit</a>
                                <button className="menu-item" onClick={e => this.deleteAccessGroup(o.id)}>Delete</button>
                            </div>
                        </div>
                        <a href={`/accessgroups/id/${o.id}`} className="scaffold-card scaffold-card-bg-1 scaffold-card-link">
                            <div className="scaffold-card-title">
                                {o.name}
                            </div>
                        </a>
                    </div>
                )}
            </div>
            <a href="/accessgroups/add" className="fab"></a>
        </Scaffold>
    )
}

const mapStateToProps = ({userState, accessGroupState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        accessGroups: accessGroupState.accessGroups
    })
}

export default connect(mapStateToProps)(AccessGroupList)