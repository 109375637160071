import React, { Dispatch } from 'react'
import './Account.css'
import LoginScaffold from './LoginScaffold.js'
import { sendPasswordResetEmail } from './resetPasswordActions.js'

type ForgotPasswordFormProps =
    {
        dispatch: Dispatch<any>,
        history: History
    }

class ForgotPasswordForm extends React.Component<ForgotPasswordFormProps, {}> {

    state = {
        email: "",
        error: "",
        displaySuccess: false
    }

    async submit(e : Event) {
        e.preventDefault()
        const response = await sendPasswordResetEmail(this.state.email)

        if (!response.success) {
            this.setState({error: response.message})
        }
        else {
            this.setState({displaySuccess: true})
        }
    }

    render() {
        return (
            <LoginScaffold>
                {this.state.displaySuccess
                 ?  <header className="Login-header">Success! Check your email for a password reset link.</header>
                 :  <div>
                        <header className="Login-header">Enter your email and we send you a password reset link.</header>
                        <header className="Login-header-error">{this.state.error}</header>
                        <form className="Login-form" onSubmit={this.submit.bind(this)}>
                            <div>
                                <input autoFocus type="text" className="Login-input" placeholder="Email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}></input>
                            </div>
                            <button type="submit" className="primary">Send request</button>
                        </form>
                    </div>
                }
            </LoginScaffold>
        )
    }
}

export default ForgotPasswordForm;