import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { ApplicationState } from '../../redux/store.js'
import { Equipment } from '../../types/equipment.js'
import { Dispatch } from 'redux'
import { PathRouteProps } from 'react-router'
import { createGqlClient } from '../../graphql-client.js'
import { gql } from '@apollo/client'
import './EquipmentList.css'
import { User } from '../../types/user.js'
import { ApolloClient, NormalizedCacheObject } from "@apollo/client"

type EquipmentListProps = {
    dispatch: Dispatch
    user: User,
    equipment: Equipment[]
} & PathRouteProps

class EquipmentList extends React.Component<EquipmentListProps,{}> {
    apolloClient : ApolloClient<NormalizedCacheObject>

    async deleteEquipment(equipmentId : string) {
        if (!window.confirm('Are you sure you want to delete?')) {
            return
        }

        await this.apolloClient.mutate({
            variables: {
                equipmentId
            },
            mutation: gql`
            mutation ($equipmentId: ID!) {
                deleteEquipment(id: $equipmentId) {
                    success
                    code
                    message
                }
              }
            `
        })
        window.location.href = window.location.href
    }
    
    componentDidMount() {
        this.apolloClient = createGqlClient()
    }

    render = () => (
        <Scaffold {...this.props}>
            <header className="scaffold-header">Equipment</header>
            <div className="scaffold-card-container">
                {this.props.equipment.map(o => 
                    <div key={o.id} className="scaffold-card-menu-container">
                        <div className="scaffold-card-menu" tabIndex={0}>
                            <div className="scaffold-card-menu-item-container">
                                <a className="menu-item" href={`/equipment/edit/${o.id}`}>Edit</a>
                                {o.isOnline && <a className="menu-item" href={`/equipment/interlocksettings/${o.id}`}>Interlock Settings</a>}
                                <button className="menu-item" onClick={e => this.deleteEquipment(o.id)}>Delete</button>
                            </div>
                        </div>
                        <a href={`/equipment/id/${o.id}`} key={o.id} className="scaffold-card scaffold-card-bg-1 scaffold-card-link">
                            <span className={`interlock-indicator ${o.isOnline ? 'online' : 'offline'}`} title={o.isOnline ? 'online' : 'offline'}/>
                            <div className="scaffold-card-title">
                                {o.name}
                            </div>
                        </a>
                    </div>
                )}
            </div>
            <header className="scaffold-header" style={{fontSize: "26px"}}>Reports</header>
            <div className="scaffold-card-container">
                <a href={`/reports/equipment-activity`} key="recent-activity-csv" className="scaffold-card scaffold-card-bg-1 scaffold-card-link" style={{width: "fit-content"}}>
                    <div className="scaffold-card-title">All Activity ⤓</div>
                </a>
            </div>
            <a href="/equipment/add" className="fab"></a>
        </Scaffold>
    )
}

const mapStateToProps = ({userState, equipmentState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        equipment: equipmentState.equipment
    })
}

export default connect(mapStateToProps)(EquipmentList)