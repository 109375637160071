import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import '../index.css'
import Routes from './Routes.js'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from '../redux/configureStore.js'

const initialState = (window as any).initialReduxState
const store = configureStore(initialState)

hydrateRoot(document.getElementById('root')!,
    <Provider store={store}>
        <BrowserRouter children={Routes} />
    </Provider>)
