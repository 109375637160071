import {sendPostRequest} from '../../node-apiRequest.js'

export const doSignup = async (fullName : string, companyName: string, email : string, password : string) => {

    const response = await sendPostRequest("/api/signup", {
        fullName,
        companyName,
        email,
        password
    })

    return {
        success: response.statusCode === 200,
        message: response.body.message
    }
}
