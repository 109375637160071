import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"

export const createGqlClient = () => {
    const cache = new InMemoryCache();
    const link = new HttpLink({
      uri: '/api/graphql'
    })
    
    const client = new ApolloClient({
      cache,
      link,
      resolvers: {},
      connectToDevTools: true
    })

    return client
}