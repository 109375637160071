import React from 'react';
import { Route, Routes } from "react-router-dom";

import routeDefinitions from './routeDefinitions.js'

export default
    <Routes>
      {routeDefinitions.map(r => {
        const Element = r.component
        return <Route key={r.path} path={r.path} element={<Element/>} />
      })}
    </Routes>;
