import React from 'react'
import './EquipmentDetail.css'
import { Equipment } from '../../types/equipment.js'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { ApplicationState } from '../../redux/store.js'
import { PathRouteProps } from 'react-router'
import { User } from '../../types/user.js'
import debounce from 'debounce'
import { Dispatch } from 'redux'
import { createGqlClient } from '../../graphql-client.js'
import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client'
import {RecentActivity} from '../../types/auditLog.js'

type EquipmentDetailProps = {
    dispatch: Dispatch
    user: User,
    equipment: Equipment
} & PathRouteProps

const getRecentActivityQuery = gql`
query getRecentActivity($equipmentId: ID, $from: ID, $direction: Direction, $fromDateTime: DateTime) {
    recentActivity(equipmentId: $equipmentId, from: $from, direction: $direction, fromDateTime: $fromDateTime, count: 100) {
        id
        eventDateTime
        description
        operatorId
        activityKind
    }
}
`

const getEquipmentMetricsQuery = gql`
query getEquipmentMetrics($equipmentId: ID, $fromDateTime: DateTime, $toDateTime: DateTime) {
    metrics(equipmentId: $equipmentId, fromDateTime: $fromDateTime, toDateTime: $toDateTime) {
        name
        value
    }
    equipmentOperatorMetrics(equipmentId: $equipmentId, fromDateTime: $fromDateTime, toDateTime: $toDateTime) {
        operatorId
        operatorName
        totalOperations
    }
}
`

class EquipmentDetail extends React.Component<EquipmentDetailProps,{}> {
    apolloClient : ApolloClient<NormalizedCacheObject>

    state = {
        recentActivity: this.props.equipment.recentActivity,
        recentActivityFromDate: '',
        equipmentStatsFromDate: '',
        equipmentStatsToDate: '',
        currentDate: '',
        metrics: this.props.equipment.metrics || [],
        equipmentOperatorMetrics: this.props.equipment.mostUsedOperators || [],
        equipmentStatsDurationType: "THIS_WEEK"
    }

    getDateStr(d : Date) {
        return `${d.getFullYear()}-${(d.getMonth()+1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
    }

    componentDidMount() {
        this.apolloClient = createGqlClient()

        const now = new Date()
        const currentDate = this.getDateStr(now)

        now.setDate(now.getDate() - 1)
        const yesterday = this.getDateStr(now)

        this.setState({
            currentDate: currentDate,
            equipmentStatsFromDate: yesterday,
            equipmentStatsToDate: currentDate
        }, () => {
            this.loadEquipmentStats()
            this.loadRecentActivityFromDate()
        })
    }

    loading = false

    loadRecentActivity = async ({fromDateTime, fromId, direction} : {fromDateTime?: string, fromId?: string, direction: string}) => {
        if (this.loading) return
        
        this.loading = true

        let results = {
            data: {
                recentActivity: [] as RecentActivity[]
            }
        }

        try {
            results = await this.apolloClient.query({
                query: getRecentActivityQuery,
                variables: {
                    equipmentId: this.props.equipment.id,
                    fromDateTime,
                    from: fromId,
                    direction
                }
            })
        } catch (e) {
            console.dir(e)
        }

        this.loading = false

        return results
    }
    
    onRecentActivityScroll = debounce(async container => {
        const scrollPos = container.scrollTop + container.clientHeight

        if (scrollPos >= container.scrollHeight) {
            const from = this.state.recentActivity[this.state.recentActivity.length - 1]
            const fromId = from.id

            const results = await this.loadRecentActivity({fromId, direction: "FORWARD"})

            if (results.data.recentActivity.length === 0) {
                return
            }

            this.setState({
                recentActivity: [...this.state.recentActivity, ...results.data.recentActivity]
            })
        }
        else if (container.scrollTop === 0) {
            const from = this.state.recentActivity[0]
            const fromId = from.id

            const results = await this.loadRecentActivity({fromId, direction: "REVERSE"})

            if (results.data.recentActivity.length === 0) {
                return
            }

            this.setState({
                recentActivity: [...results.data.recentActivity, ...this.state.recentActivity]
            })
        }

    }, 100);

    getRecentActivityClassName(re : RecentActivity) {
        switch (re.activityKind) {
            case "INTERLOCK_MODULE":
                return "scaffold-card-recent-activity-status bg-color-1"
            case "USER_ACTIVITY":
                return "scaffold-card-recent-activity-status bg-color-2"
            case "UNAUTHORIZED_ACTIVITY":
                return "scaffold-card-recent-activity-status bg-color-3"
            case "RELAY_SETTINGS":
                return "scaffold-card-recent-activity-status bg-color-4"
            default:
                return "scaffold-card-recent-activity-status bg-color-1"
        }
    }

    skipToRecentActivityDate = async e => {
        e.preventDefault()

        await this.loadRecentActivityFromDate()
    }

    setRecentActivityDate(date) {
        this.setState({recentActivityFromDate: date}, 
            this.loadRecentActivityFromDate);
    }

    async loadRecentActivityFromDate() {

        const recentActivityDiv = document.getElementById('recent-activity-div')

        if (!Date.parse(this.state.recentActivityFromDate)) {
            return
        }

        const fromDate = new Date(this.state.recentActivityFromDate)
        fromDate.setDate(fromDate.getDate()+1)
        fromDate.setHours(23,59,59,999)

        const results = await this.loadRecentActivity({fromDateTime: new Date(fromDate.toUTCString()).toISOString(), direction: "FORWARD"})

        if (results.data.recentActivity.length === 0) {
            return
        }

        this.setState({
            recentActivity: results.data.recentActivity
        }, () => recentActivityDiv.scrollTo(0,0))

    }

    async selectEquipmentStatsDuration(e) {
        const duration = e.target.value

        this.setState({
            equipmentStatsDurationType: duration
        }, this.loadEquipmentStats)
    }

    async loadEquipmentStats() {
        let fromDateTime = new Date()
        let toDateTime = new Date()

        switch(this.state.equipmentStatsDurationType) {
            case "CUSTOM_DURATION":
                if (!Date.parse(this.state.equipmentStatsFromDate) ||
                    !Date.parse(this.state.equipmentStatsToDate))
                    return

                fromDateTime = new Date(this.state.equipmentStatsFromDate + "T00:00:00")
                toDateTime = new Date(this.state.equipmentStatsToDate + "T00:00:00")
                break
            case "THIS_MONTH":
                fromDateTime.setDate(1)
                break
            case "THIS_WEEK":
            default:
                fromDateTime.setDate(fromDateTime.getDate() - fromDateTime.getDay())
                break
        }

        fromDateTime.setHours(0,0,0,0)
        toDateTime.setHours(23,59,59,999)

        const results = await this.apolloClient.query({
            query: getEquipmentMetricsQuery,
            variables: {
                equipmentId: this.props.equipment.id,
                fromDateTime: new Date(fromDateTime.toUTCString()).toISOString(),
                toDateTime: new Date(toDateTime.toUTCString()).toISOString()
            }
        })

        this.setState({
            metrics: results.data.metrics,
            equipmentOperatorMetrics: results.data.equipmentOperatorMetrics
        })
    }

    setEquipmentStatsFromDate(date) {
        this.setState({
            equipmentStatsFromDate: date
        }, this.loadEquipmentStats)
    }
    
    setEquipmentStatsToDate(date) {
        this.setState({
            equipmentStatsToDate: date
        }, this.loadEquipmentStats)
    }

    async setEquipmentStatsDuration(e) {
        e.preventDefault()

        await this.loadEquipmentStats()
    }

    render = () => (
        <Scaffold {...this.props} >
            <header className="scaffold-header">{this.props.equipment.name} Overview</header>
            <div className="scaffold-select-container">
                <form onSubmit={e => this.setEquipmentStatsDuration(e)} className="form-set-equipment-stats">
                    <label className="scaffold-select">
                        <select onChange={e => this.selectEquipmentStatsDuration(e)}>
                            <option value="THIS_WEEK">This week</option>
                            <option value="THIS_MONTH">This month</option>
                            <option value="CUSTOM_DURATION">Custom duration</option>
                        </select>
                    </label>
                    {this.state.equipmentStatsDurationType === "CUSTOM_DURATION" &&
                    <span>
                        <label className="form-set-equipment-stats-label-date">From <input type="date" value={this.state.equipmentStatsFromDate} onChange={e => this.setEquipmentStatsFromDate(e.target.value)}></input></label>
                        <label className="form-set-equipment-stats-label-date">To <input type="date" max={this.state.currentDate} value={this.state.equipmentStatsToDate} onChange={e => this.setEquipmentStatsToDate(e.target.value)}></input></label>
                        <button style={{display: 'none'}}></button>
                    </span>}
                </form>
            </div>
            <div className="scaffold-card-container">
                <div className="scaffold-card-stats">
                    {this.state.metrics.map(metric => (
                    <div key={metric.name} className="scaffold-card scaffold-card-bg-1">
                        <div className="scaffold-card-title">{metric.name}</div>
                        <div className="scaffold-card-body">{metric.value}</div>
                    </div>
                    ))}
                    <div className="scaffold-card scaffold-card-bg-3">
                        <div className="scaffold-card-title">Most used operators</div>
                        <div className="scaffold-card-body-list">
                            {this.state.equipmentOperatorMetrics.map(l => (
                                <div key={l.operatorId}><strong><a href={`/operators/id/${l.operatorId}`}>{l.operatorName}</a></strong> — {l.totalOperations} operations</div>
                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="scaffold-card scaffold-card-bg-3 scaffold-card-recent-activity">
                        <div className="scaffold-card-title">Recent activity</div>
                        <div className="scaffold-card-subtitle">
                            <form onSubmit={e => this.skipToRecentActivityDate(e)}>
                                <label>Jump to date <input className="input-recent-activity-date" type="date" max={this.state.currentDate} onChange={e => this.setRecentActivityDate(e.target.value)}/></label>
                                <button style={{display:'none'}}></button>
                            </form>
                        </div>
                        <div id="recent-activity-div" className="scaffold-card-body-recent-activity" onScroll={e => this.onRecentActivityScroll(e.currentTarget)}>
                            {this.state.recentActivity.map(re => (
                                <div key={re.id} className="row">
                                    <div className="col">{new Date(re.eventDateTime).toLocaleDateString()}</div>
                                    <div className="col">{new Date(re.eventDateTime).toLocaleTimeString()}</div>
                                    <div className={this.getRecentActivityClassName(re)}>{re.operatorId != null ? <a href={`/operators/id/${re.operatorId}`}>{re.description}</a> : re.description}</div>
                                </div>
                            ))}
                        </div>
                        <div className="scaffold-card-recent-activity-legend">
                            <div className="row">
                                <div className="col"><span className="scaffold-card-recent-activity-legend-ind bg-color-1"></span>Interlock Module</div>
                                <div className="col"><span className="scaffold-card-recent-activity-legend-ind bg-color-2"></span>User Activity</div>
                            </div>
                            <div className="row">
                                <div className="col"><span className="scaffold-card-recent-activity-legend-ind bg-color-3"></span>Unauthorized Activity</div>
                                <div className="col"><span className="scaffold-card-recent-activity-legend-ind bg-color-4"></span>Relay Settings</div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Scaffold>
    )
}

const mapStateToProps = ({userState, equipmentState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        equipment: equipmentState.equipment[0]
    })
}

export default connect(mapStateToProps)(EquipmentDetail)