import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { Dispatch } from 'redux'
import {createGqlClient} from '../../graphql-client.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { ApplicationState } from '../../redux/store.js'
import { Equipment } from '../../types/equipment.js'
import { User } from '../../types/user.js'

type EquipmentEditFormProps = {
    dispatch: Dispatch
    user: User,
    equipment: Equipment
} & PathRouteProps

class EquipmentEditForm extends React.Component<EquipmentEditFormProps,{}> {

    state = {
        name: this.props.equipment.name,
        description: this.props.equipment.description,
        location: this.props.equipment.location,
        interlockId: this.props.equipment.interlockId,
        message: ''
    }

    async submit(e : Event) {
        e.preventDefault()
        
        this.setState({
            message: ''
        })
        
        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                equipmentId: this.props.equipment.id,
                equipment: {
                    name: this.state.name,
                    description: this.state.description,
                    location: this.state.location,
                    interlockId: this.state.interlockId
                }
            },
            mutation: gql`
            mutation ($equipmentId: ID!, $equipment:UpdateEquipmentInputType!) {
                updateEquipment(id: $equipmentId, equipment: $equipment) {
                  success
                  code
                  message
                }
              }
            `
        })

        if (result.data.updateEquipment.success === false) {
            this.setState({message: result.data.updateEquipment.message})
            return;
        }

        window.location.href = '/equipment'
    }

    render() {
        return (
            <Scaffold {...this.props} autofocusSearch={false}>
                <header className="scaffold-header">Edit Equipment</header>
                <header className="header-error">{this.state.message}</header>
                <form onSubmit={this.submit.bind(this)}>
                    <div>
                        <div className="input-field-container">
                            <label className="input-field-label">Name<input autoFocus type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                            </label>
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-label">Description<input type="text" value={this.state.description} onChange={e => this.setState({description: e.target.value})}/>
                            </label>
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-label">Location<input type="text" value={this.state.location} onChange={e => this.setState({location: e.target.value})}/>
                            </label>
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-label">Interlock ID<input type="text" value={this.state.interlockId} onChange={e => this.setState({interlockId: e.target.value})}/>
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="primary">Save</button>
                </form>
            </Scaffold>
        )
    }
}

const mapStateToProps = ({userState, equipmentState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        equipment: equipmentState.equipment[0]
    })
}

export default connect(mapStateToProps)(EquipmentEditForm)