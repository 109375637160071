import { equipmentState } from './equipment.js'
import { operatorState } from './operator.js'
import { accessGroupState } from './accessGroup.js'
import { userState, userListState } from './user.js'
import { EquipmentCollectionState } from '../types/equipment.js'
import { OperatorCollectionState } from '../types/operator.js'
import { AccessGroupCollectionState } from '../types/accessGroup.js'
import { UserState, UserListState } from '../types/user.js'

// The top-level state object
export interface ApplicationState {
    equipmentState: EquipmentCollectionState,
    operatorState: OperatorCollectionState,
    accessGroupState: AccessGroupCollectionState,
    userState: UserState,
    userListState: UserListState
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    equipmentState,
    operatorState,
    accessGroupState,
    userState,
    userListState
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}
