import React from 'react'
import './EquipmentDetail.css'
import { Equipment } from '../../types/equipment.js'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { ApplicationState } from '../../redux/store.js'
import { PathRouteProps } from 'react-router'
import { User } from '../../types/user.js'
import './InterlockSettings.css'
import { createGqlClient } from '../../graphql-client.js'
import { gql } from '@apollo/client'

type InterlockSettingsFormProps = {
    user: User,
    equipment: Equipment
} & PathRouteProps

class InterlockSettingsForm extends React.Component<InterlockSettingsFormProps,{}> {
    state = {
        minAmpsToDetermineRunningState: this.props.equipment.interlockSettings.minAmpsToDetermineRunningState,
        automaticOff: this.props.equipment.interlockSettings.automaticOff,
        turnEquipmentOffInSeconds: this.props.equipment.interlockSettings.turnEquipmentOffInSeconds,
        disableRelayifRunning: this.props.equipment.interlockSettings.disableRelayifRunning,
        isRelayInverted: this.props.equipment.interlockSettings.isRelayInverted,
        isUnderMaintenance: this.props.equipment.interlockSettings.isUnderMaintenance,
        updateLocalAccessDBAutomatically: this.props.equipment.interlockSettings.updateLocalAccessDBAutomatically,
        localAccessDbUpdateIntervalMinutes: this.props.equipment.interlockSettings.localAccessDbUpdateIntervalMinutes,
        errorMessage: '',
        isSyncing: false,
        syncError: false
    }

    async submit(e) {
        e.preventDefault()

        this.setState({
            errorMessage: ''
        })
        
        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                equipmentId: this.props.equipment.id,
                interlockSettings: {
                    minAmpsToDetermineRunningState: this.state.minAmpsToDetermineRunningState,
                    automaticOff: this.state.automaticOff,
                    turnEquipmentOffInSeconds: this.state.turnEquipmentOffInSeconds,
                    disableRelayifRunning: this.state.disableRelayifRunning,
                    isRelayInverted: this.state.isRelayInverted,
                    isUnderMaintenance: this.state.isUnderMaintenance,
                    updateLocalAccessDBAutomatically: this.state.updateLocalAccessDBAutomatically,
                    localAccessDbUpdateIntervalMinutes: this.state.localAccessDbUpdateIntervalMinutes
                }
            },
            mutation: gql`
            mutation ($equipmentId:ID!, $interlockSettings:SaveInterlockSettingsInputType!) {
                saveInterlockSettings(equipmentId: $equipmentId, interlockSettings: $interlockSettings) {
                    success
                    code
                    message
                }
              }
            `
        })

        if (result.data.saveInterlockSettings.success === false) {
            this.setState({errorMessage: result.data.saveInterlockSettings.message})
            return;
        }

        window.location.href = '/equipment'
    }

    async syncAccessDb(e) {
        e.preventDefault()
        this.setState({
            isSyncing: true,
            syncError: false
        })

        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                equipmentId: this.props.equipment.id
            },
            mutation: gql`
            mutation ($equipmentId:ID!) {
                syncInterlockAccessDb(equipmentId: $equipmentId) {
                    success
                    code
                    message
                }
              }
            `
        })

        if (result.data.syncInterlockAccessDb.success === false) {
            this.setState({
                isSyncing: false,
                syncError: true
            })
            return;
        }

        this.setState({
            isSyncing: false,
            syncError: false
        })
    }

    render = () => (
        <Scaffold {...this.props} autofocusSearch={false} >
            <header className="scaffold-header">{this.props.equipment.name} Interlock Device Settings</header>
            {this.props.equipment.interlockSettings.connectionFailed
            ? <header className="header-error">Unable to connect. Try again later.</header>
            :   
                <div>
                    <header className="header-error">{this.state.errorMessage}</header>
                    <div className="scaffold-card scaffold-card-bg-1">
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="scaffold-card-title">Settings</div>
                            <div className="interlocksettings-setting">
                                <label><span>Running when exceeding</span>
                                <input type="number"
                                    autoFocus
                                    className="interlocksettings-input-number"
                                    value={this.state.minAmpsToDetermineRunningState}
                                    onChange={e => this.setState({minAmpsToDetermineRunningState: Number(e.target.value)})}></input>
                                    <span>amps</span>
                                    </label>
                            </div>
                            <div className="interlocksettings-setting">
                                <label><input type="checkbox"
                                    className="interlocksettings-input-checkbox"
                                    checked={this.state.automaticOff}
                                    onChange={e => this.setState({automaticOff: e.target.checked})}></input>
                                    <span>Turn power off after</span></label>
                                <label>
                                <input type="number"
                                    className="interlocksettings-input-number"
                                    value={this.state.turnEquipmentOffInSeconds}
                                    onChange={e => this.setState({turnEquipmentOffInSeconds: Number(e.target.value)})}></input>
                                    <span>seconds if equipment is not running</span>
                                    </label>
                            </div>
                            <div className="interlocksettings-setting">
                                <label><input type="checkbox"
                                    className="interlocksettings-input-checkbox"
                                    checked={this.state.disableRelayifRunning}
                                    onChange={e => this.setState({disableRelayifRunning: e.target.checked})}></input>
                                    <span>Disable relay if running</span></label>
                            </div>
                            <div className="interlocksettings-setting">
                                <label><input type="checkbox"
                                    className="interlocksettings-input-checkbox"
                                    checked={this.state.isRelayInverted}
                                    onChange={e => this.setState({isRelayInverted: e.target.checked})}></input>
                                    <span>Invert relay</span></label>
                            </div>
                            <div className="interlocksettings-setting">
                                <label><input type="checkbox"
                                    className="interlocksettings-input-checkbox"
                                    checked={this.state.isUnderMaintenance}
                                    onChange={e => this.setState({isUnderMaintenance: e.target.checked})}></input>
                                    <span>Under maintenance</span></label>
                            </div>
                            <div className="interlocksettings-setting">
                                <label><input type="checkbox"
                                    className="interlocksettings-input-checkbox"
                                    checked={this.state.updateLocalAccessDBAutomatically}
                                    onChange={e => this.setState({updateLocalAccessDBAutomatically: e.target.checked})}></input>
                                    <span>Synchronize access database every</span></label>
                                <label><input type="number"
                                    className="interlocksettings-input-number"
                                    value={this.state.localAccessDbUpdateIntervalMinutes}
                                    onChange={e => this.setState({localAccessDbUpdateIntervalMinutes: Number(e.target.value)})}></input>
                                    <span>minutes</span>
                                    </label>
                                    <button className="sync-now-btn" onClick={this.syncAccessDb.bind(this)}>Sync now</button>
                                    {this.state.isSyncing && <div className="sync-now-loader"></div>}
                                    {this.state.syncError && <div className="sync-now-error"></div>}
                            </div>
                            <button className="primary">Save</button>
                        </form>
                    </div>
                </div>}
        </Scaffold>
    )
}

const mapStateToProps = ({userState, equipmentState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        equipment: equipmentState.equipment[0]
    })
}

export default connect(mapStateToProps)(InterlockSettingsForm)