import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { Dispatch } from 'redux'
import {createGqlClient} from '../../graphql-client.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { ApplicationState } from '../../redux/store.js'
import { Operator } from '../../types/operator.js'
import { User } from '../../types/user.js'

type OperatorEditFormProps = {
    dispatch: Dispatch
    user: User,
    operator: Operator
} & PathRouteProps

class OperatorEditForm extends React.Component<OperatorEditFormProps,{}> {

    state = {
        name: this.props.operator.name,
        rfid: this.props.operator.rfid,
        email: this.props.operator.email || '',
        isDisabled: this.props.operator.isDisabled,
        message: ''
    }

    async submit(e : Event) {
        e.preventDefault()

        this.setState({
            message: ''
        })
        
        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                operatorId: this.props.operator.id,
                operator: {
                    name: this.state.name,
                    rfid: this.state.rfid,
                    email: this.state.email,
                    isDisabled: this.state.isDisabled
                }
            },
            mutation: gql`
            mutation ($operatorId: ID!, $operator:UpdateOperatorInputType!) {
                updateOperator(id: $operatorId, operator: $operator) {
                    success
                    code
                    message
                }
              }
            `
        })

        if (result.data.updateOperator.success === false) {
            this.setState({message: result.data.updateOperator.message})
            return;
        }

        window.location.href = '/operators'
    }

    render() {
        return (
            <Scaffold {...this.props} autofocusSearch={false}>
                <header className="scaffold-header">Edit Operator</header>
                <header className="header-error">{this.state.message}</header>
                <form onSubmit={this.submit.bind(this)}>
                    <div>
                        <div className="input-field-container">
                            <label className="input-field-label">Name<input autoFocus type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                            </label>
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-label">RFID<input type="text" value={this.state.rfid} onChange={e => this.setState({rfid: e.target.value})}/>
                            </label>
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-label">Email<input type="text" value={this.state.email} onChange={e => this.setState({email: e.target.value})}/>
                            </label>
                        </div>
                        <div className="input-field-container">
                            <label className="input-field-label"><input type="checkbox" checked={this.state.isDisabled} onChange={e => this.setState({isDisabled: e.target.checked})}/> 
                            Disabled
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="primary">Edit</button>
                </form>
            </Scaffold>
        )
    }
}

const mapStateToProps = ({userState, operatorState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        operator: operatorState.operators[0]
    })
}

export default connect(mapStateToProps)(OperatorEditForm)