import { gql } from '@apollo/client'
import { Action, Reducer } from "redux"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { OperatorCollectionState, Operator } from "../types/operator.js"

export const OPERATOR_COLLECTION_GET = 'OPERATOR_COLLECTION_GET'
export const OPERATOR_COLLECTION_APPEND = 'OPERATOR_COLLECTION_APPEND'

export const getOperatorCollection = (apolloClient: ApolloClient<NormalizedCacheObject>) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query GetOperatorCollection {
                operators(count: 100) {
                    id
                    name
                    rfid
                    email
                    isDisabled
                }
            }
            `
        })

        return dispatch({
            type: OPERATOR_COLLECTION_GET,
            data: results.data
        })
    } 
}

export const getNextOperatorCollection = (apolloClient: ApolloClient<NormalizedCacheObject>, from: string) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query GetOperatorCollection {
                operators(count: 100, from: "${from}") {
                    id
                    name
                    rfid
                    email
                    isDisabled
                }
            }
            `
        })

        return dispatch({
            type: OPERATOR_COLLECTION_APPEND,
            data: results.data
        })
    } 
}
export const getSingleOperator = (apolloClient: ApolloClient<NormalizedCacheObject>, id: string) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query GetOperatorCollection {
                operators(id: "${id}") {
                    id
                    name
                    rfid
                    email
                    isDisabled
                    accessGroups {
                        id
                        name
                    }
                    authorizedEquipment {
                        id
                        name
                    }
                    recentActivity {
                        id
                        description
                        eventDateTime
                        equipmentId
                        activityKind
                    }
                }
            }
            `
        })

        return dispatch({
            type: OPERATOR_COLLECTION_GET,
            data: results.data
        })
    } 
}

export const defaultState: OperatorCollectionState = {
    operators: []
}

export interface GetOperatorCollectionAction {
    type: string,
    data: OperatorCollectionState
}

export const operatorState: Reducer<OperatorCollectionState> = (state = defaultState, incomingAction: Action) => {
    const action = incomingAction as GetOperatorCollectionAction
    switch (action.type) {
        case OPERATOR_COLLECTION_GET:
            return action.data
        case OPERATOR_COLLECTION_APPEND:
            return {
                ...state,
                operators: [...state.operators, ...action.data.operators]
            }
        default:
            return state
    }
}
