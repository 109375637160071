import React, { Dispatch } from 'react'
import {doSignup} from './signupActions.js'
import './Account.css'
import LoginScaffold from './LoginScaffold.js'

type SignupFormProps =
    {
        dispatch: Dispatch<any>,
        history: History
    }

class SignupForm extends React.Component<SignupFormProps, {}> {
    state = {
        fullName: "",
        companyName: "",
        email: "",
        password: "",
        error: "",
        showPassword: false,
        didAcceptTerms: false
    }

    async signup(e : Event) {
        e.preventDefault()
        if (this.state.didAcceptTerms == false) {
            this.setState({error: "You must accept the terms to continue."})

            return
        }
        
        const response = await doSignup(this.state.fullName, this.state.companyName, this.state.email, this.state.password)

        if (!response.success) {
            this.setState({error: response.message || "An error occurred. Please try again."})
        }
        else {
            this.setState({error: ""})
            window.location.href = `/`
        }
    }

    render() {
        return (
            <LoginScaffold>
                <header className="Login-header">Please complete to create your account.</header>
                <header className="Login-header-error">{this.state.error}</header>
                <form className="Login-form" onSubmit={this.signup.bind(this)}>
                    <div className="Login-form-inputs">
                        <div>
                            <input autoFocus type="text" className="Login-input" placeholder="First and last name" value={this.state.fullName} onChange={e => this.setState({fullName: e.target.value})}></input>
                        </div>
                        <div>
                            <input type="text" className="Login-input" placeholder="Company name" value={this.state.companyName} onChange={e => this.setState({companyName: e.target.value})}></input>
                        </div>
                        <div>
                            <input type="text" className="Login-input" placeholder="Email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}></input>
                        </div>
                        <div>
                            <input type={this.state.showPassword ? "text" : "password"} className="Login-input" placeholder="Password" value={this.state.password} onChange={e => this.setState({password: e.target.value})}></input>
                            <label><input type="checkbox" className="Login-input-checkbox" checked={this.state.showPassword} onChange={e => this.setState({showPassword: e.target.checked})}></input><span>Show</span></label>
                        </div>
                        <div>
                            <label><input type="checkbox" className="Login-input-checkbox" checked={this.state.didAcceptTerms} onChange={e => this.setState({didAcceptTerms: e.target.checked})}></input><span>I agree with terms and conditions</span></label>
                        </div>
                    </div>
                    <button type="submit" className="primary">Sign up</button>
                    <div>
                        <a className="Login-signin-link" href="/login">Already have an account? Sign in.</a>
                    </div>
                </form>
            </LoginScaffold>
        )
    }
}

export default SignupForm;