import React, { Dispatch } from 'react'
import './Account.css'
import {doLogin} from './loginActions.js'
import LoginScaffold from './LoginScaffold.js'

type LoginFormProps =
    {
        dispatch: Dispatch<any>,
        history: History
    } & any

class LoginForm extends React.Component<LoginFormProps, {}> {

    state = {
        email: "",
        password: "",
        error: ""
    }

    async login(e: Event) {
        e.preventDefault()

        const response = await doLogin(this.state.email, this.state.password)

        if (!response.success) {
            this.setState({error: response.message})
        }
        else {
            this.setState({error: ""})
            window.location.href = `/`
        }
    }

    signup() {
        this.props.history.push('/signup')
    }

    render() {
        return (
            <LoginScaffold>
                <header className="Login-header">Welcome back! Please login to your account.</header>
                <header className="Login-header-error">{this.state.error}</header>
                <form className="Login-form" onSubmit={this.login.bind(this)}>
                    <div>
                        <input autoFocus type="text" className="Login-input" placeholder="Email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}></input>
                    </div>
                    <input type="password" className="Login-input" placeholder="Password" value={this.state.password} onChange={e => this.setState({password: e.target.value})}></input>
                    <div>
                        <a href="/forgot-password" className="Login-forgot-password">Forgot password</a>
                    </div>
                    <button type="submit" className="primary">Login</button>
                    <button onClick={this.signup.bind(this)}>Sign up</button>
                </form>
            </LoginScaffold>
        )
    }
}

export default LoginForm