import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { Dispatch } from 'redux'
import {createGqlClient} from '../../graphql-client.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { User } from '../../types/user.js'
import { ApplicationState } from '../../redux/store.js'

type AccessGroupAddFormProps = {
    dispatch: Dispatch
    user: User
} & PathRouteProps

class AccessGroupAddForm extends React.Component<AccessGroupAddFormProps,{}> {

    state = {
        name: '',
        message: ''
    }

    async submit(e : Event) {
        e.preventDefault()

        this.setState({
            message: ''
        })
        
        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                accessGroup: {
                    name: this.state.name
                }
            },
            mutation: gql`
            mutation ($accessGroup:CreateAccessGroupInputType!) {
                createAccessGroup(accessGroup: $accessGroup) {
                    success
                    code
                    message
                }
              }
            `
        })

        if (result.data.createAccessGroup.success === false) {
            this.setState({message: result.data.createAccessGroup.message})
            return;
        }

        window.location.href = '/accessgroups'
    }

    render() {
        return (
            <Scaffold {...this.props} autofocusSearch={false}>
                <header className="scaffold-header">Add an Access Group</header>
                <header className="header-error">{this.state.message}</header>
                <form onSubmit={this.submit.bind(this)}>
                    <div>
                        <div><input autoFocus placeholder="Name" type="text" onChange={e => this.setState({name: e.target.value})}/></div>
                    </div>
                    <button type="submit" className="primary">Add</button>
                </form>
            </Scaffold>
        )
    }
}

const mapStateToProps = ({userState} : ApplicationState) => {
    return (
    {
        user: userState.user
    })
}

export default connect(mapStateToProps)(AccessGroupAddForm)