import React from 'react'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { Dispatch } from 'redux'
import {createGqlClient} from '../../graphql-client.js'
import { PathRouteProps } from 'react-router'
import { gql } from '@apollo/client'
import { ApplicationState } from '../../redux/store.js'
import { AccessGroup } from '../../types/accessGroup.js'
import { User } from '../../types/user.js'

type AccessGroupEditFormProps = {
    dispatch: Dispatch
    user: User,
    accessGroup: AccessGroup
} & PathRouteProps

class AccessGroupEditForm extends React.Component<AccessGroupEditFormProps,{}> {

    state = {
        name: this.props.accessGroup.name,
        message: ''
    }

    async submit(e : Event) {
        e.preventDefault()

        this.setState({
            message: ''
        })
        
        const apolloClient = createGqlClient()
        const result = await apolloClient.mutate({
            variables: {
                accessGroupId: this.props.accessGroup.id,
                accessGroup: {
                    name: this.state.name
                }
            },
            mutation: gql`
            mutation ($accessGroupId: ID!, $accessGroup:UpdateAccessGroupInputType!) {
                updateAccessGroup(id: $accessGroupId, accessGroup: $accessGroup) {
                    success
                    code
                    message
                }
              }
            `
        })

        if (result.data.updateAccessGroup.success === false) {
            this.setState({message: result.data.updateAccessGroup.message})
            return;
        }

        window.location.href = '/accessgroups'
    }

    render() {
        return (
            <Scaffold {...this.props} autofocusSearch={false}>
                <header className="scaffold-header">Edit Access Group</header>
                <header className="header-error">{this.state.message}</header>
                <form onSubmit={this.submit.bind(this)}>
                    <div>
                        <div className="input-field-container">
                            <label className="input-field-label">Name<input autoFocus type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="primary">Edit</button>
                </form>
            </Scaffold>
        )
    }
}

const mapStateToProps = ({userState, accessGroupState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        accessGroup: accessGroupState.accessGroups[0]
    })
}

export default connect(mapStateToProps)(AccessGroupEditForm)