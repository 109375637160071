import React from 'react'
import './AccessGroupDetail.css'
import Scaffold from '../Scaffold/Scaffold.js'
import {connect} from 'react-redux'
import { ApplicationState } from '../../redux/store.js'
import { Dispatch } from 'redux'
import { PathRouteProps } from 'react-router'
import { AccessGroup } from '../../types/accessGroup.js'
import {debounce} from 'debounce'
import { createGqlClient } from '../../graphql-client.js'
import { gql } from '@apollo/client'
import { getSingleAccessGroup } from '../../redux/accessGroup.js'
import { User } from '../../types/user.js'

type AccessGroupDetailProps = {
    dispatch: Dispatch
    user: User,
    accessGroup: AccessGroup
} & PathRouteProps

class AccessGroupDetail extends React.Component<AccessGroupDetailProps,{}> {

    async addOperator(id) {
        this.setState({
            operatorSearchResults: []
        })

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                accessGroupId: this.props.accessGroup.id,
                operatorId: id
            },
            mutation: gql`
            mutation ($accessGroupId:ID!, $operatorId:ID!) {
                addOperatorToAccessGroup(accessGroupId: $accessGroupId, operatorId: $operatorId) {                  
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleAccessGroup(apolloClient, this.props.accessGroup.id)(this.props.dispatch)
    }

    async addEquipment(id) {
        this.setState({
            equipmentSearchResults: []
        })

        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                accessGroupId: this.props.accessGroup.id,
                equipmentId: id
            },
            mutation: gql`
            mutation ($accessGroupId:ID!, $equipmentId:ID!) {
                addEquipmentToAccessGroup(accessGroupId: $accessGroupId, equipmentId: $equipmentId) {
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleAccessGroup(apolloClient, this.props.accessGroup.id)(this.props.dispatch)
    }

    async removeOperator(id) {
        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                accessGroupId: this.props.accessGroup.id,
                operatorId: id
            },
            mutation: gql`
            mutation ($accessGroupId:ID!, $operatorId:ID!) {
                removeOperatorFromAccessGroup(accessGroupId: $accessGroupId, operatorId: $operatorId) {                  
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleAccessGroup(apolloClient, this.props.accessGroup.id)(this.props.dispatch)
    }

    async removeEquipment(id) {
        const apolloClient = createGqlClient()
        await apolloClient.mutate({
            variables: {
                accessGroupId: this.props.accessGroup.id,
                equipmentId: id
            },
            mutation: gql`
            mutation ($accessGroupId:ID!, $equipmentId:ID!) {
                removeEquipmentFromAccessGroup(accessGroupId: $accessGroupId, equipmentId: $equipmentId) {                  
                    success
                    code
                    message
                }
              }
            `
        })

        await getSingleAccessGroup(apolloClient, this.props.accessGroup.id)(this.props.dispatch)
    }
    
    searchString = ''

    state = {
        equipmentSearchResults: [],
        operatorSearchResults: []
    }

    debouncedSearchOperator = debounce(this.searchOperator, 20)

    debouncedSearchEquipment = debounce(this.searchEquipment, 20)

    async searchEquipment(searchString: string) {
        this.searchString = searchString
        if (searchString == ''){
            this.setState({
                equipmentSearchResults: []
            })
            return;
        }
        
        const apolloClient = createGqlClient()
        const results = await apolloClient.query({
            query: gql`
            query Search {
                search(searchString: "${searchString}", count: 8, type: EQUIPMENT){
                    id
                    name
                    type
                }
            }
            `
        })

        if (this.searchString != searchString) {
            return
        }
        
        this.setState({
            equipmentSearchResults: results.data.search
        })
    }

    async searchOperator(searchString: string) {
        this.searchString = searchString
        if (searchString == ''){
            this.setState({
                operatorSearchResults: []
            })
            return;
        }
        
        const apolloClient = createGqlClient()
        const results = await apolloClient.query({
            query: gql`
            query Search {
                search(searchString: "${searchString}", count: 8, type: OPERATOR){
                    id
                    name
                    type
                }
            }
            `
        })

        if (this.searchString != searchString) {
            return
        }
        
        this.setState({
            operatorSearchResults: results.data.search
        })
    }

    render = () => (
        <Scaffold {...this.props}>
            <header className="scaffold-header">{this.props.accessGroup.name}</header>
            <div className="scaffold-card-container">
                <div>
                    <div className="scaffold-card scaffold-card-bg-1">
                        <div className="scaffold-card-title">Details</div>
                        <div className="scaffold-card-detail">
                            <div className="scaffold-card-detail-label">Name</div> 
                            {this.props.accessGroup.name}
                        </div>
                    </div>
                    <div className="scaffold-card scaffold-card-bg-1">
                        <div className="scaffold-card-title">Authorized Equipment</div>
                        <div className="ag-list-item input-search-list-container">
                            <input type="search" placeholder="Equipment name" onChange={e => this.debouncedSearchEquipment(e.target.value)}></input>
                            {this.state.equipmentSearchResults.length > 0 &&
                                <ul className="input-search-list">
                                    {this.state.equipmentSearchResults.map(sr => (
                                        <li key={sr.id} onClick={e => this.addEquipment(sr.id)} className="input-search-listitem">{sr.name}</li>
                                    ))}
                                </ul>
                            }
                        </div>
                        <div className="scaffold-card-body-list">
                            {this.props.accessGroup.authorizedEquipment.map(l => (
                                <div key={l.id} className="ag-list-item"><a href={`/equipment/id/${l.id}`}>{l.name}</a> <button onClick={e => this.removeEquipment(l.id)} className="ag-btn-small">x</button></div>
                            ))}
                        </div>
                    </div>
                    <div className="scaffold-card scaffold-card-bg-1">
                        <div className="scaffold-card-title">Authorized Operators</div>
                        <div className="ag-list-item input-search-list-container">
                            <input type="search" placeholder="Operator name" onChange={e => this.debouncedSearchOperator(e.target.value)}></input>
                            {this.state.operatorSearchResults.length > 0 &&
                                <ul className="input-search-list">
                                    {this.state.operatorSearchResults.map(sr => (
                                        <li key={sr.id} onClick={e => this.addOperator(sr.id)} className="input-search-listitem">{sr.name}</li>
                                    ))}
                                </ul>
                            }
                        </div>
                        <div className="scaffold-card-body-list">
                            {this.props.accessGroup.authorizedOperators.map(l => (
                                <div key={l.id} className="ag-list-item"><a href={`/operators/id/${l.id}`}>{l.name}</a> <button onClick={e => this.removeOperator(l.id)} className="ag-btn-small">x</button></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </Scaffold>
    )
}

const mapStateToProps = ({userState, accessGroupState} : ApplicationState) => {
    return (
    {
        user: userState.user,
        accessGroup: accessGroupState.accessGroups[0]
    })
}

export default connect(mapStateToProps)(AccessGroupDetail)