import { gql } from '@apollo/client'
import { Action, Reducer } from "redux"
import { ApolloClient, NormalizedCacheObject } from "@apollo/client"
import { AccessGroupCollectionState } from "../types/accessGroup.js"

export const ACCESSGROUP_COLLECTION_GET = 'ACCESSGROUP_COLLECTION_GET'

export const getAccessGroupCollection = (apolloClient: ApolloClient<NormalizedCacheObject>) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query getAccessGroupCollection {
                accessGroups(count: 100) {
                    id
                    name
                }
            }
            `
        })

        return dispatch({
            type: ACCESSGROUP_COLLECTION_GET,
            data: results.data
        })
    } 
}

export const getSingleAccessGroup = (apolloClient: ApolloClient<NormalizedCacheObject>, accessGroupId: string) => {

    return async dispatch => {
        const results = await apolloClient.query({
            query: gql`
            query GetSingleAccessGroup {
                accessGroups(id: "${accessGroupId}"){
                    id
                    name
                    authorizedEquipment {
                        id
                        name
                    }
                    authorizedOperators {
                        id
                        name
                    }
                }
            }
            `
        })

        return dispatch({
            type: ACCESSGROUP_COLLECTION_GET,
            data: {
                accessGroups: results.data.accessGroups
            }
        })
    }
}

export const defaultState: AccessGroupCollectionState = {
    accessGroups: []
}

export interface GetAccessGroupAction {
    type: string
    data: AccessGroupCollectionState
}

export const accessGroupState: Reducer<AccessGroupCollectionState> = (state = defaultState, incomingAction: Action) => {
    const action = incomingAction as GetAccessGroupAction

    switch (action.type) {
        case ACCESSGROUP_COLLECTION_GET:
            return action.data
        default:
            return state
    }
}

