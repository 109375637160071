import {sendPostRequest, sendPutRequest} from '../../node-apiRequest.js'

export const sendPasswordResetEmail = async (email : string) => {

    const response = await sendPostRequest("/api/resetpassword", { email })

    return {
        success: response.statusCode === 200,
        message: response.body.message
    }
}

export const resetPassword = async (passwordResetToken : string, password: string) => {

    const response = await sendPutRequest("/api/resetpassword", { passwordResetToken, password })

    return {
        success: response.statusCode === 200,
        message: response.body.message
    }
}
