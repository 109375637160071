import React from 'react'
import './Scaffold.css'
import iconOperator from '../../icons/icons8-worker-24.png'
import iconEquipment from '../../icons/icons8-plug-24.png'
import iconAccessGroups from '../../icons/icons8-lock-50.png'
import iconSettings from '../../icons/icons8-settings-50.png'
import { createGqlClient } from '../../graphql-client.js'
import { gql } from '@apollo/client'
import {debounce} from 'debounce'
import { PathRouteProps } from 'react-router'
import { sendPostRequest } from '../../node-apiRequest.js'
import {User} from '../../types/user.js'

type ScaffoldProps = {
    user: User,
    autofocusSearch?: boolean,
} & PathRouteProps

class Scaffold extends React.Component<ScaffoldProps,{}> {

    searchString = ''

    state = {
        searchResults: [] as {
            id: string,
            type: string,
            name: string
        }[]
    }

    routes = {
        equipment: ['/','/equipment','/equipment/id/:id', '/equipment/add', '/equipment/edit/:id', '/equipment/interlocksettings/:id'],
        operators: ['/operators','/operators/id/:id', '/operators/add', '/operators/edit/:id'],
        accessGroups: ['/accessgroups','/accessgroups/id/:id', '/accessgroups/add', '/accessgroups/edit/:id'],
        settings: ['/settings','/settings/users']
    }

    debouncedSearch = debounce(this.search, 20)

    async search(searchString: string) {
        this.searchString = searchString
        if (searchString == ''){
            this.setState({
                searchResults: []
            })
            return;
        }
        
        const apolloClient = createGqlClient()
        const results = await apolloClient.query({
            query: gql`
            query Search {
                search(searchString: "${searchString}", count: 8){
                    id
                    name
                    type
                }
            }
            `
        })

        if (this.searchString != searchString) {
            return
        }
        
        this.setState({
            searchResults: results.data.search
        })
    }

    getRoute(sr)  {
        switch (sr.type) {
            case 'EQUIPMENT':
                return `/equipment/id/${sr.id}`
            case 'OPERATOR':
                return `/operators/id/${sr.id}`
            case 'ACCESS_GROUP':
                return `/accessgroups/id/${sr.id}`
            default:
                return '#'
        }
    }

    getIcon(sr) {
        switch (sr.type) {
            case 'EQUIPMENT':
                return <img src={iconEquipment} className="scaffold-search-icon" alt={sr.name}/>
            case 'OPERATOR':
                return <img src={iconOperator} className="scaffold-search-icon" alt={sr.name}/>
            case 'ACCESS_GROUP':
                return <img src={iconAccessGroups} className="scaffold-search-icon" alt={sr.name}/>
            default:
                return <div/>
        }
    }

    getNavbarItemClassName(routes: string[]) {
        return routes.find(route => this.props.path == route) ? "scaffold-navbar-item-highlight" : "scaffold-navbar-item";
    }

    getNavbarIconClassName(routes: string[]) {
        return routes.find(route => this.props.path == route) ? "scaffold-navbar-icon scaffold-navbar-icon-highlight" : "scaffold-navbar-icon";
    }

    async logout() {
        await sendPostRequest("/api/logout")
        window.location.href = '/'
    }

    render = () => (
        <div className="scaffold">
            <div className="scaffold-scaffold-top">
                <a href="/"><div className="scaffold-logo"/></a>
                <div className="scaffold-navbar-top">
                    <div className="scaffold-navbar-top-search">
                        <span className="scaffold-search-input-icon"></span>
                        <input autoFocus={this.props.autofocusSearch || this.props.autofocusSearch == undefined} className="scaffold-navbar-top-search-input" type="search" placeholder="Search equipment, access groups, or operators..." onChange={e => this.debouncedSearch(e.target.value)}></input>
                        {this.state.searchResults.length > 0 &&
                        <ul className="scaffold-navbar-top-search-list">
                            {this.state.searchResults.map(sr => (
                                <a key={`${sr.type}-${sr.id}`} href={this.getRoute(sr)}><li className="scaffold-navbar-top-search-listitem">{this.getIcon(sr)} {sr.name}</li></a>
                            ))}
                        </ul>}
                    </div>
                    <div className="scaffold-navbar-top-vertical-bar"></div>
                    <div className="scaffold-navbar-top-user-fullname" tabIndex={0}>
                        {this.props.user.name}
                        <div className="scaffold-navbar-top-user-menu-item-container">
                            <button className="menu-item" onClick={e => this.logout()}>Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scaffold-navbar-left">
                <div className={this.getNavbarItemClassName(this.routes.equipment)}><a href="/equipment"><img className={this.getNavbarIconClassName(this.routes.equipment)} src={iconEquipment} alt="equipment"></img></a></div>
                <div className={this.getNavbarItemClassName(this.routes.operators)}><a href="/operators"><img className={this.getNavbarIconClassName(this.routes.operators)} src={iconOperator} alt="operators"></img></a></div>
                <div className={this.getNavbarItemClassName(this.routes.accessGroups)}><a href="/accessgroups"><img className={this.getNavbarIconClassName(this.routes.accessGroups)} src={iconAccessGroups} alt="access groups"></img></a></div>
                {this.props.user.role === 'ADMIN' && <div className={this.getNavbarItemClassName(this.routes.settings)}><a href="/settings"><img className={this.getNavbarIconClassName(this.routes.settings)} src={iconSettings} alt="settings"></img></a></div>}
            </div>
            <div className="scaffold-main">
                <div className="scaffold-content">
                    {this.props.children}
                </div>
            </div>
        </div>
  )
}

export default Scaffold
