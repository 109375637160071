import React from 'react'
import rfidPhoto from '../../rfidphoto.jpg'
import logo from '../../rfidinterlock-logo.png'
import './Account.css'

const LoginScaffold: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <div className="Login-container">
      <div className="Login-rfid-photo-container">
        <img src={rfidPhoto} className="Login-rfid-photo" alt="rfid reader" />
      </div>
      <div className="Login-form-container">
        <img src={logo} className="Login-logo" alt="logo"/>
        {children}
      </div>
    </div>
  );
}

export default LoginScaffold;
