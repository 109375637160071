import React from 'react'
import {connect} from 'react-redux'
import { Dispatch } from 'redux'
import { PathRouteProps } from 'react-router'
import Scaffold from '../Scaffold/Scaffold.js'
import './Settings.css'
import { ApplicationState } from '../../redux/store.js'
import { User } from '../../types/user.js'

type SettingsProps = {
    dispatch: Dispatch
    user: User
} & PathRouteProps

class Settings extends React.Component<SettingsProps,{}> {
    
    render = () => (
        <Scaffold {...this.props}>
            <div>
                <a href="/settings/users" className="settings-list-item">Users <span className="chevron right"></span></a>
            </div>
        </Scaffold>
    )
}

const mapStateToProps = ({userState} : ApplicationState) => {
    return (
    {
        user: userState.user
    })
}

export default connect(mapStateToProps)(Settings)